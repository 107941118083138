body {
  font-family: "Public Sans", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.icon-container {
  margin: 0 auto;
}

.icon-container svg {
  font-size: 36px;
  margin: 0 4px;
  text-shadow: 4px 4px 4px grey;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.2s;
  color: black;
}

.invisible {
  right: -1000px;
}

.open {
  color: rgba(0, 0, 0, 0);
}

.open:before {
  transform: rotate(90deg);
  top: 10px;
  color: black;
  left: 4px;
}

.open:after {
  top: 8px;
  color: black;
}

.visible {
  right: 0px;
}

.parallax-outer {
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100vw;
}

.parallax-inner {
  position: sticky;
  top: calc(50vh - 150px);
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  padding: 25px;
  box-sizing: border-box;
  display: block;
  width: 80vw;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

button {
  background: rgba(0, 0, 0, 0);
  height: 50px;
  color: black;
  font-weight: 900;
  font-size: 24px;
  width: 50px;
  position: relative;
  transition: all 0.1s;
}

button:before {
  content: "—";
  top: 4px;
  left: 0;
  right: 0;
  position: absolute;
  transition: all 0.1s;
}

button:after {
  content: "—";
  top: 12px;
  left: 0;
  right: 0;
  position: absolute;
  transition: all 0.1s;
}

.lil-guy {
  font-size: 56px;
}

footer {
  background: black;
  text-align: center;
  padding: 4px;
  color: white;
}

h1, h2, h3, h4 {
  color: black;
  text-shadow: 2px 2px 2px grey;
}

h1 {
  font-weight: 900;
  margin: 0 auto 0 0;
  padding: 4px 8px;
  color: black;
}

h2 {
  text-align: center;
  font-style: italic;
  font-weight: 900;
  font-size: 32px;
  margin: 2px auto;
  color: white;
}

h3 {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  margin: 2px auto;
}

header {
  width: 100vw;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0);
  top: 0;
  transition: all 0.3s;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  padding: 16px;
  top: 50px;
  z-index: 0; 
  right: 0;
}

nav a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: black;
  font-weight: 700;
  border-bottom: 1px solid black;
  width: fit-content;
  transition: all 0.2s;
}

@media (hover: hover) {
  .icon-container svg:hover {
    color: white; 
  }

  nav a {
    border-bottom: 1px solid transparent;
  }

  nav a:hover {
    border-bottom: 1px solid black;
  }
}

@media screen and (min-width: 768px) {
  h2 {
    font-size: 64px;
  }

  h3 {
    font-size: 32px;
  }
}
